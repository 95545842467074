<template>
    <div class="bg-white page" id="home">
        <b-carousel id="carousel-1"
                    :interval="5000"
                    fade
                    controls
                    indicators
                    style="text-shadow: 1px 1px 2px #333;">
            <!--Text slides with image-->
            <!--<b-carousel-slide caption="First slide"
                              text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                              :img-src="homePhoto2">

            </b-carousel-slide>-->

            <!--Slides with custom text-->
            <!--<b-carousel-slide :img-src="homePhoto3">
                <h1>Hello world!</h1>
            </b-carousel-slide>-->

            <!--Slides with image only-->
            <!--<b-carousel-slide :img-src="homePhoto4"></b-carousel-slide>-->
            <!--<b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58"></b-carousel-slide>-->

            <!--Slides with img slot-->
            <!--Note the classes .d-block and .img-fluid to prevent browser default image alignment-->
            <b-carousel-slide v-for="slider in sliders" v-bind:key="slider.id">
                <template #img>
                    <img class="d-block img-fluid w-100 slider-img"
                         :src="slider.imageSrc"
                         @contextmenu.prevent="rightClick"
                         :alt="slider.imageAlt">
                </template>

                <template #default >
                    <h1 class="text-primary">{{ $t(slider.caption) | uppercase }}</h1>
                </template>

            </b-carousel-slide>

            <!--Slide with blank fluid image to maintain slide aspect ratio-->
            <!--<b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eros felis, tincidunt
                    a tincidunt eget, convallis vel est. Ut pellentesque ut lacus vel interdum.
                </p>
            </b-carousel-slide>-->

        </b-carousel>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sliders: [
                    {
                        id: 1,
                        title: null,
                        imageAlt:"",
                        imageSrc: require("@/assets/img/home/sliders/slider_1.jpg"),
                        caption: "aboutUs.subtitle"
                    },
                    {
                        id: 2,
                        title: null,
                        imageAlt: "",
                        imageSrc: require("@/assets/img/home/sliders/slider_2.jpg"),
                        caption: "aboutUs.subtitle"
                    },
                    {
                        id: 3,
                        title: null,
                        imageAlt: "",
                        imageSrc: require("@/assets/img/home/sliders/slider_3.jpg"),
                        caption: "aboutUs.subtitle"
                    },
                    //{
                    //    id: 6,
                    //    title: null,
                    //    imageAlt: "",
                    //    imageSrc: require("@/assets/img/home/sliders/slider_6.jpg"),
                    //    caption: "aboutUs.subtitle"
                    //},
                    {
                        id: 4,
                        title: null,
                        imageAlt: "",
                        imageSrc: require("@/assets/img/home/sliders/slider_4.jpg"),
                        caption: "aboutUs.subtitle"
                    },
                    {
                        id: 5,
                        title: null,
                        imageAlt: "",
                        imageSrc: require("@/assets/img/home/sliders/slider_5.jpg"),
                        caption: "aboutUs.subtitle"
                    },
                   

                ]


            };
        },
        methods: {
            rightClick(e) {
                e.preventDefault();
            }
        },
        filters: {
            uppercase(text) {
                return text.toUpperCase();
            }
        }
    };
</script>

<style scoped>

    @media (max-width: 1599px) {
        .page {
            padding-top: 8rem !important;
        }

        .slider-img {
            object-fit: fill;
            max-height: 50rem !important;
        }
    }

    @media (min-width: 1600px) {
        .page {
            padding-top: 8.5rem !important;
        }


        .slider-img {
            object-fit: fill;
            max-height: 75rem !important;
        }
    }

</style>
