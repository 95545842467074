<template>
    <div>
        <div style="transform: rotate(180deg); height: 150px; overflow: hidden">
            <svg viewBox="0 0 500 150"
                 preserveAspectRatio="none"
                 style="height: 100%; width: 100%">
                <path d="M0,75 C250,150 250,150 500.00,75 L500.00,150.00 L0.00,150.00 Z"
                      style="stroke: none; fill: #2164ae"></path>
            </svg>
        </div>

        <footer class="pb-1">
            <div class="container">
                <div class="row px-3 mb-5 d-flex justify-content-around">
                    <div class="col-md-12 d-flex align-items-center">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11508.407841272065!2d18.383281546531336!3d43.853715319003655!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x45c507925e6a0590!2sHK%20ING%20d.o.o.!5e0!3m2!1sbs!2sba!4v1638393783152!5m2!1sbs!2sba" width="100%" height="300px" class="rounded " style="border:1px dashed #2164ae;" allowfullscreen="true" loading="lazy"></iframe>
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-md-3 text-center text-primary mb-3">
                        <h3 class="mb-2 fw-bold">{{ $t("footer.company") }}</h3>
                        <p class="mb-1">{{ company.name }}</p>
                        <p>{{ company.uniqueIdNumber }}</p>
                    </div>
                    <div class="col-md-3 text-center text-primary mb-3">
                        <h3 class="mb-2 fw-bold">{{ $t("footer.bank") }}</h3>
                        <p class="mb-1">{{ bank.name }}</p>
                        <p>{{ bank.accountNumber }}</p>
                    </div>

                    <div class="col-md-3 text-center text-primary mb-3">
                        <h3 class="mb-2 fw-bold">{{ $t("footer.location") }}</h3>
                        <p class="mb-1">{{ location.address }}</p>
                        <p>{{ location.postalCode }}</p>
                    </div>
                    <div class="col-md-3 text-center text-primary mb-3">
                        <h3 class="mb-2 fw-bold">{{ $t("footer.contact") }}</h3>
                        <a class="footer-link d-block" :href="'mailto:' + contact.email">{{ contact.email }}</a>
                        <a class="footer-link" :href="'tel:' + contact.phone" >{{ contact.phone }}</a>
                    </div>
                </div>
            </div>

            <!--<div class="text-center to-the-top-btn mt-lg-0">
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="36"
                         height="36"
                         fill="white"
                         class="bi bi-arrow-up-circle"
                         viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                    </svg>
                </a>
            </div>-->
            <p class="text-center pt-5 text-primary noselect small" v-html="$t('footer.copyright')"></p>
        </footer>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                company: {
                    name: "HK ING d.o.o.",
                    uniqueIdNumber: "ID: 4202748910006",
                },
                bank: {
                    name: "Intesa Sanpaolo Banka d.d. Bosna i Hercegovina",
                    accountNumber: "1540012013407145",
                },
                location: {
                    address: "Kolodvorska 11a",
                    postalCode: "71000, Sarajevo",
                },
                contact: {
                    email: "info@hking-consult.com",
                    phone: "+387 61 386 003",
                },
                footerLogoSrc: require("@/assets/logo/hking-logo-bs.png"),
            };
        },
    };
</script>

<style></style>
