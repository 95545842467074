import axios from "axios";
import i18n from "../i18n";

const hking_api = axios.create({
    baseURL: 'https://api.hking-consult.com'
});


hking_api.interceptors.request.use(
    request => {
        request.headers["Accept-Language"] = i18n.locale;

        return request;
    }
);

export { hking_api };

