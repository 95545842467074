<template>
    <div class="page bg-blue text-light" id="servicesDetails">
        <div class="container">
            <div class="text-center px-3">
                <div class="mb-5">
                    <div class="row">
                        <div class="col-md-3 mb-1 text-center align-self-center pointer" 
                             @click="selectService(service.id)"
                             v-for="service in services"
                             v-bind:key="service.id">
                            <div class="small p-2 border service-item border-white rounded" :class="{'selected-service': service.id == selectedServiceId}">
                                {{ $t(service.title) | uppercase }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row px-3">
                <div class="col-md-12">
                    <div class="border shadow-soft rounded-4 p-2" id="nav">
                        <b-container class="bv-example-row p-0">
                            <b-row>
                                <b-col v-for="serviceItem in selectedService.serviceItems"
                                       v-bind:key="serviceItem.id"
                                       class="d-flex justify-content-center pointer">
                                    <a class="func-button text-center text-decoration-none rounded-4 align-items-center scale-on-hover-02"
                                       :class="{'func-button-clicked': selectedServiceItem.id == serviceItem.id}"
                                       @click="selectServiceItem(serviceItem.id)">
                                        <div v-if="serviceItem.icon" class="material-icons">
                                            {{ serviceItem.icon }}
                                        </div>
                                        <div class="m-0">{{ $t(serviceItem.title) }}</div>
                                    </a>
                                </b-col>
                            </b-row>
                        </b-container>
                        <!--<div class="row">
                            <div class="col-lg-3 mb-2 mb-lg-0 d-flex justify-content-center pointer"
                                 v-for="serviceItem in selectedService.serviceItems"
                                 v-bind:key="serviceItem.id">
                                <a class="func-button text-center rounded-4 align-items-center scale-on-hover-05"
                                   :class="{'func-button-clicked': selectedServiceItem.id == serviceItem.id}"
                                   @click="selectServiceItem(serviceItem.id)">
                                    <div v-if="serviceItem.icon" class="material-icons me-2">
                                        {{ serviceItem.icon }}
                                    </div>
                                    <div class="m-0">{{ $t(serviceItem.title) }}</div>
                                </a>
                            </div>
                        </div>-->
                    </div>
                    <div id="tab-content">
                        <div id="content-research">
                            <div class="row pt-5 justify-content-between align-items-start">
                                <div class="col-md-6 mb-4 mb-lg-0">
                                    <h3 class="mb-5">{{ $t(selectedServiceItem.title) }}</h3>
                                    <div class="text-justify" v-html="$t(selectedServiceItem.text)"></div>
                                    <!--<div class="text-justify">
                                        <p>Upravljanje multidisciplinarnim projektima u građevinarstvu (supervizija od ideje, izrade projektne dokumentacije, nadzora do primopredaje objekta) za:</p>
                                        <ul>
                                            <li>Individualne objekte</li>
                                            <li>Naselja</li>
                                            <li>Infrastrukturne projekte</li>
                                            <li>Hidroenergetske objekte</li>
                                        </ul>
                                    </div>-->
                                </div>
                                <div class="col-12 col-md-6">
                                    <img class="shadow rounded-4 img-fluid"
                                         @contextmenu.prevent="rightClick"
                                         :src="selectedServiceItem.src"
                                         alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { eventBus } from "../main.js"

    export default {
        data() {
            return {
                selectedServiceId: 1,
                selectedServiceItemId: 1,
                services: [
                    {
                        id: 1,
                        title: "services.structures.title",
                        subtitle: "services.structures.subtitle",
                        icon: "dashboard",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.structures.sturcturalAnalysis.title",
                                icon: "multiline_chart",
                                text: "services.structures.sturcturalAnalysis.text",
                                src: require("@/assets/img/home/sliders/slider_1.jpg"),
                            },
                            {
                                id: 2,
                                title: "services.structures.reinforcementPlans.title",
                                icon: "grid_4x4",
                                text: "services.structures.reinforcementPlans.text",
                                src: require("@/assets/img/services/structures/reinforcement-plans.jpg"),

                            },
                            {
                                id: 3,
                                title: "services.structures.formworkPlans.title",
                                icon: "view_column",
                                text: "services.structures.formworkPlans.text",
                                src: require("@/assets/img/services/structures/revetment-plans.jpg"),
                            },
                            {
                                id: 4,
                                title: "services.structures.details.title",
                                icon: "details",
                                text: "services.structures.details.text",
                                src: require("@/assets/img/services/structures/details.jpg"),
                            }

                        ]
                    },
                    {
                        id: 2,
                        title: "services.hydro.title",
                        subtitle: "services.hydro.subtitle",
                        icon: "invert_colors",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.hydro.waterSupplyAndSewerage.title",
                                icon: "stacked_line_chart",
                                text: "services.hydro.waterSupplyAndSewerage.text",
                                src: require("@/assets/img/services/hydro/waterSupplyAndSewerage.png"),
                            },
                            {
                                id: 2,
                                title: "services.hydro.waterActs.title",
                                icon: "task",
                                text: "services.hydro.waterActs.text",
                                src: require("@/assets/img/services/hydro/waterActs.png"),
                            },
                            {
                                id: 3,
                                title: "services.hydro.hydropower.title",
                                icon: "bloodtype",
                                text: "services.hydro.hydropower.text",
                                src: require("@/assets/img/services/hydro/hydropower.jpg"),
                            },
                            {
                                id: 4,
                                title: "services.hydro.watercourseRegulation.title",
                                icon: "water",
                                text: "services.hydro.watercourseRegulation.text",
                                src: require("@/assets/img/services/hydro/watercourseRegulation.jpg"),
                            },
                            //{
                            //    id: 5,
                            //    title: "services.hydro.drainage.title",
                            //    icon: "invert_colors_off",
                            //    text: "services.hydro.drainage.text",
                            //    src: require("@/assets/img/home/sliders/slider_1.jpg"),
                            //}

                        ]
                    },
                    {
                        id: 3,
                        title: "services.engineering.title",
                        subtitle: "services.engineering.subtitle",
                        icon: "settings_input_component",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.engineering.projectManagement.title",
                                icon: "account_tree",
                                text: "services.engineering.projectManagement.title",
                                src: require("@/assets/img/home/sliders/slider_1.jpg"),
                            },
                            {
                                id: 2,
                                title: "services.engineering.constructionSupervision.title",
                                icon: "visibility",
                                text: "services.engineering.constructionSupervision.title",
                                src: require("@/assets/img/home/sliders/slider_1.jpg"),
                            }
                        ]
                    },
                    {
                        id: 4,
                        title: "services.bim.title",
                        subtitle: "services.bim.subtitle",
                        icon: "view_in_ar",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.bim.modeling.title",
                                icon: "view_in_ar",
                                text: "services.bim.modeling.text",
                                src: require("@/assets/img/services/bim/Building-Information-Modeling.jpg"),
                            },
                            {
                                id: 2,
                                title: "services.bim.clashDetection.title",
                                icon: "query_stats",
                                text: "services.bim.clashDetection.text",
                                src: require("@/assets/img/services/bim/Building-Information-Modeling.jpg"),
                            }
                        ]
                    }
                ]
            };
        },
        methods: {
            selectService(serviceId) {

                this.selectedServiceId = serviceId;
                this.selectedServiceItemId = 1;
            },
            selectServiceItem(serviceItemId) {
                this.selectedServiceItemId = serviceItemId;
            },
            rightClick(e) {
                e.preventDefault();
            }
        },
        computed: {
            selectedService() {
                return this.services.find(s => s.id == this.selectedServiceId);
            },
            selectedServiceItem() {
                return this.selectedService.serviceItems.find(si => si.id == this.selectedServiceItemId);
            }
        },
        filters: {
            uppercase(text) {
                return text.toUpperCase();
            }
        },
        created() {
            eventBus.$on("serviceChanged", (serviceId) => {
                this.selectService(serviceId);
            });

            eventBus.$on("serviceItemChanged", (data) => {
                this.selectService(data.serviceId);
                this.selectServiceItem(data.serviceItemId);
            });
        }

    };
</script>

<style scoped>
    

        @media(max-width: 600px)
        {
            .selected-service {
                font-weight: bold !important;
                background-color: white;
                color: #2164ae;
            }

                .service-item:hover {
                    font-weight: bold !important;
                    background-color: white;
                    color: #2164ae;
                }

        }

    @media(min-width: 501px) and (max-width: 980px) {
        .selected-service {
            font-weight: bold !important;
            background-color: white;
            color: #2164ae;
        }

        .service-item {
            font-size: 15px !important;
            transition: font-size .5s;
        }

            .service-item:hover {
                font-weight: bold !important;
                background-color: white;
                color: #2164ae;
            }
    }


    @media(min-width: 1000px) {
        .selected-service {
            font-weight: bold !important;
            background-color: white;
            color: #2164ae;
        }

        .service-item {
            font-size: 20px !important;
            transition: font-size .5s;
           
        }

            .service-item:hover {
                font-weight: bold !important;
                background-color: white;
                color: #2164ae;
            }
    }

    .page{
        padding-top: 4rem;
    }

</style>
