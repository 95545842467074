import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import i18n from './i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false


import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.esm";
import "@popperjs/core";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "./assets/css/custom.css";


Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
export const eventBus = new Vue();


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

