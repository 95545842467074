<template>
    <div class="dropdown " id="navbar">
        <button class="btn btn-outline-primary scale-on-hover dropdown-toggle small font-weight-bold" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img :src="require('../assets/flags/' + selectedLanguage.code + '.png')" height="20" />
            <span class="sm-d-none small ml-5">{{ selectedLanguage.name }}</span>
            <span class="md-d-none lg-d-none small ml-5">{{ selectedLanguage.code | uppercase }}</span>
        </button>
        <div class="dropdown-menu rounded" aria-labelledby="dropdownMenuButton">
            <button class="dropdown-item" v-for="language in selectableLanguages" v-bind:key="language.code" v-on:click="changeLanguage(language.code)">
                <img :src="require('../assets/flags/' + language.code + '.png')" height="20" />
                <span class="ml-10 sm-d-none">{{ language.name }}</span>
                <span class="ml-10 md-d-none lg-d-none">{{ language.code | uppercase }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { availableLanguages } from "../../data/db.json"

    export default {
        name: "LanguageSwitcher",
        data() {
            return {
                availableLanguages
            }
        },
        methods: {
            changeLanguage(lang) {
                this.$i18n.locale = lang;
                localStorage.setItem("lang", lang);
            }
        },
        computed: {
            selectableLanguages() {
                return availableLanguages.filter(x => x.code != this.$i18n.locale)
            },
            selectedLanguage() {
                return availableLanguages.find(x => x.code == this.$i18n.locale);
            }
        },
        filters: {
            uppercase(text) {
                return text.toUpperCase();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
