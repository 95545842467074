<template>
    <div class="page pt-5" id="services">
        <div class="mb-5 text-center text-primary px-3">
            <h1 class="fw-bold mb-2">{{ $t("services.title") }}</h1>
            <p class="pb-3">{{ $t("services.subtitle") }}</p>
        </div>
        <div class="container px-3">
            <div class="row ms-0 me-0">
                <div class="col-md-12 col-lg-3 mb-4"
                     v-for="service in services"
                     :key="service.id">
                    <div class="bg-primary-gradient rounded p-3 pt-5 pb-5 text-center card-height pointer" @click.self="selectService(service.id)">
                        <div class="mb-3" @click="selectService(service.id)">
                            <span class="material-icons m-icons-services">{{service.icon}}</span>
                        </div>
                        <div class="text-light">
                            <h5 class="mb-4 letter-span-01" @click="selectService(service.id)">{{ $t(service.title) | uppercase }}</h5>
                            <ul class="list-group">
                                <li v-for="serviceItem in service.serviceItems" v-bind:key="serviceItem.id" @click="selectServiceItem(service.id, serviceItem.id)" class="list-group-item scale-on-hover-02 pointer rounded">{{ $t(serviceItem.title) }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { eventBus } from "../main.js"

    export default {
        data() {
            return {
                services: [
                    {
                        id: 1,
                        title: "services.structures.title",
                        icon: "dashboard",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.structures.sturcturalAnalysis.title"
                            },
                            {
                                id: 2,
                                title: "services.structures.reinforcementPlans.title"
                            },
                            {
                                id: 3,
                                title: "services.structures.formworkPlans.title"
                            },
                            {
                                id: 4,
                                title: "services.structures.details.title"
                            }

                        ]
                    },
                    {
                        id: 2,
                        title: "services.hydro.title",
                        icon: "invert_colors",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.hydro.waterSupplyAndSewerage.title"
                            },
                            {
                                id: 2,
                                title: "services.hydro.waterActs.title"
                            },
                            {
                                id: 3,
                                title: "services.hydro.hydropower.title"
                            },
                            {
                                id: 4,
                                title: "services.hydro.watercourseRegulation.title"
                            }
                        ]
                    },
                    {
                        id: 3,
                        title: "services.engineering.title",
                        icon: "settings_input_component",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.engineering.projectManagement.title"
                            },
                            {
                                id: 2,
                                title: "services.engineering.constructionSupervision.title"
                            }
                        ]
                    },
                    {
                        id: 4,
                        title: "services.bim.title",
                        icon: "view_in_ar",
                        serviceItems: [
                            {
                                id: 1,
                                title: "services.bim.modeling.title"
                            },
                            {
                                id: 2,
                                title: "services.bim.clashDetection.title"
                            }
                        ]
                    }
                ],
            };
        },
        methods: {
            selectService(serviceId) {
                eventBus.$emit("serviceChanged", serviceId);
                document.getElementById("servicesDetails").scrollIntoView(true);
            },
            selectServiceItem(serviceId, serviceItemId) {
                eventBus.$emit("serviceItemChanged", {serviceId, serviceItemId});
                document.getElementById("servicesDetails").scrollIntoView(true);
            },
        },
        filters: {
            uppercase(text) {
                return text.toUpperCase();
            }
        }
    };
</script>

<style scoped>

    .list-group-item {
        border: 1px solid rgba(33, 100, 174) !important;
    }

</style>
