<template>
    <div id="app">
        <Navbar />
        <Home />
        <AboutUs />
        <Services />
        <ServicesDetails />
        <Projects />
        <Contact />
        <Footer />
    </div>
</template>

<script>
    import Navbar from "./components/Navbar.vue";
    import Home from "./components/Home.vue";
    import AboutUs from "./components/AboutUs.vue";
    import Services from "./components/Services.vue";
    import ServicesDetails from "./components/ServicesDetails.vue";
    import Projects from "./components/Projects.vue";
    import Contact from "./components/Contact.vue";
    import Footer from "./components/Footer.vue";
    import LanguageSwitcher from "./components/LanguageSwitcher.vue";

    export default {
        name: "App",
        components: {
            Navbar,
            Home,
            AboutUs,
            ServicesDetails,
            Services,
            Projects,
            Contact,
            Footer,
            LanguageSwitcher,
        },
        filters: {
            uppercase(text) {
                return text.toUpperCase();
            }
        },
        beforeMount() {
            let lang = localStorage.getItem("lang");
            if (lang && this.$i18n.availableLocales.includes(lang)) {
                this.$i18n.locale = lang;
            }
        }
    };
</script>

<style>
</style>
