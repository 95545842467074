<template>
    <div class="page px-4 bg-blue" id="contact-us">
        <div class="container">
            <div class="mb-5 text-center px-3">
                <h1 class="fw-bold mb-2 text-light">{{ $t("contactUs.title") }}</h1>
                <p class="text-light pb-3" v-html="$t('contactUs.subtitle')"></p>
            </div>
       
            <div v-if="contactSuccess" class="row d-flex justify-content-center contact-success-container">
                <div class="col-sm-12 col-md-8 col-lg-8 text-primary bg-white rounded p-4 text-justify shadow">
                    <h3 class="text-center font-weight-bold mb-3">{{ $t('contactUs.contactSuccessMsgTitle') }}</h3>
                    <hr />
                    <div v-html="$t('contactUs.contactSuccessMsg')"></div>
                    <hr />
                    <div class="d-flex justify-content-center">
                        <button type="button" @click="resetForm(true)" class="btn btn-lg btn-primary">{{ $t("contactUs.close") }} <span v-show="closeButtonTimer !== null"> ({{ closeButtonTimer }})</span></button>
                    </div>
                </div>
            </div>
            <div v-else-if="contactError" class="row d-flex justify-content-center contact-error-container">
                <div class="col-sm-12 col-md-8 col-lg-8 text-primary bg-white rounded p-4 text-justify shadow">
                    <h3 class="text-center font-weight-bold text-danger mb-3">{{ $t('contactUs.contactErrorMsgTitle') }}</h3>
                    <hr />
                    <div v-html="$t('contactUs.contactErrorMsg', {email: companyEmail})"></div>
                    <hr />
                    <div class="d-flex justify-content-center">
                        <button type="button" @click="closeErrorPopup(true)" class="btn btn-lg btn-primary">{{ $t("contactUs.close") }} <span v-show="closeButtonTimer !== null"> ({{ closeButtonTimer }})</span></button>
                    </div>
                </div>
            </div>
            <form v-else class="text-light">
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                        <label for="fullName" class="form-label">{{ $t("contactUs.fullName") }}</label>
                        <input type="text" v-model="$v.fullName.$model" class="form-control" :placeholder="$t('contactUs.fullNamePlaceholder')" :class="{ 'err-border': $v.fullName.$dirty && $v.fullName.$invalid }"/>
                        <span class="err-msg text-danger small font-weight-bold" v-if="$v.fullName.$dirty && $v.fullName.$invalid">{{ $t("contactUs.fullNameIsRequired") }}</span>

                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                        <label for="email" class="form-label">{{ $t("contactUs.email") }}</label>
                        <input type="email" v-model="$v.email.$model" class="form-control" :placeholder="$t('contactUs.emailPlaceholder')" :class="{ 'err-border': $v.email.$dirty && !$v.email.required }" />
                        <span class="err-msg text-danger small font-weight-bold" v-if="$v.email.$dirty && !$v.email.required">{{ $t("contactUs.emailIsRequired") }}</span>
                        <span class="err-msg text-danger small font-weight-bold" v-if="$v.email.$dirty && !$v.email.email">{{ $t("contactUs.emailFormatIsIncorrect") }}</span>
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                        <label for="phone" class="form-label">{{ $t("contactUs.phone") }}</label>
                        <input type="tel" v-model="$v.phone.$model" class="form-control" :placeholder="$t('contactUs.phonePlaceholder')" :class="{ 'err-border': $v.phone.$dirty && $v.phone.$invalid }"/>
                        <span class="err-msg text-danger small font-weight-bold" v-if="$v.phone.$dirty && $v.phone.$invalid">{{ $t("contactUs.phoneIsRequired") }}</span>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 mb-3">
                        <label for="companyName" class="form-label">{{ $t("contactUs.companyName") }}</label>
                        <input type="text" v-model="companyName" class="form-control" :placeholder="$t('contactUs.companyNamePlaceholder')" />
                    </div>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-10">
                        <label for="messageContent" class="form-label">{{ $t("contactUs.messageContent") }}</label>
                        <textarea class="form-control" v-model="$v.messageContent.$model" :placeholder="$t('contactUs.messageContentPlaceholder')" rows="4" required="required" :class="{ 'err-border': $v.messageContent.$dirty && $v.messageContent.$invalid }"></textarea>
                        <span class="err-msg text-danger small font-weight-bold" v-if="$v.messageContent.$dirty && $v.messageContent.$invalid">{{ $t("contactUs.messageContentIsRequired") }}</span>
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-center">
                    <button type="button" @click="sendEmail()" class="btn btn-lg btn-primary"> {{ $t("contactUs.send") }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

    import { required, email } from 'vuelidate/lib/validators'
    import { hking_api } from '../http/hking_axios'

    export default {
        name: "Contact",
        data() {
            return {
                companyEmail: "info@hking-consult.com",
                fullName: "",
                phone: "",
                email: "",
                companyName: "",
                messageContent: "",
                contactError: false,
                contactSuccess: false,
                closeButtonTimer: null
            };
        },
        methods: {
            sendEmail() {
                this.$v.$touch();

                if (this.$v.$invalid)
                    return;

                try {
                    hking_api.post("contact", { fullName: this.fullName, phone: this.phone, email: this.email, companyName: this.companyName, messageContent: this.messageContent })
                        .then(() => {
                            this.contactSuccess = true;
                            this.closeButtonTimer = 30;
                            this.resetForm();
                        })
                        .catch(() => {
                            this.contactError = true;
                            this.closeButtonTimer = 30;
                            this.closeErrorPopup();
                        });
                } catch {
                    this.contactError = true;
                    this.closeButtonTimer = 30;
                    this.closeErrorPopup();
                }
            },
            resetForm(hideSuccessMsgImmediate = false) {
                this.fullName = this.phone = this.email = this.messageContent = this.companyName = "";
                this.$v.$reset();

                if (hideSuccessMsgImmediate) {
                    this.contactError = this.contactSuccess = false;
                } else {

                    var timerCloseInterval = setInterval(() => {
                        this.closeButtonTimer--;

                    }, 1000);

                    setTimeout(() => {
                        this.contactError = this.contactSuccess = false;
                        this.closeButtonTimer = null;
                        clearInterval(timerCloseInterval);

                    }, this.closeButtonTimer * 1000);

                }
            },
            closeErrorPopup(hideErrorMsgImmediate = false) {
                if (hideErrorMsgImmediate) {
                    this.contactError = this.contactSuccess = false;
                } else {

                    var timerCloseInterval = setInterval(() => {
                        this.closeButtonTimer--;

                    }, 1000);

                    setTimeout(() => {
                        this.contactError = this.contactSuccess = false;
                        this.closeButtonTimer = null;
                        clearInterval(timerCloseInterval);

                    }, this.closeButtonTimer * 1000);

                }
            }
        },
        validations: {
            fullName: {required},
            phone: {required},
            email: {
                required,
                email
            },
            messageContent: {required}
        },
        mounted() {
            
        }
    };
</script>

<style>
    .err-border {
        border: 2px solid #C72020 !important;
        transition: 0.5s;
    }

</style>
