<template>
    <div>
        <svg id="wave"
             style="transform: rotate(180deg); transition: 0.3s"
             viewBox="0 0 1440 230"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(33,100,174, 1)" offset="0%"></stop>
                    <stop stop-color="rgba(33,100,174, 1)" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path style="transform: translate(0, 0px); opacity: 1"
                  fill="url(#sw-gradient-0)"
                  d="M0,92L80,99.7C160,107,320,123,480,118.8C640,115,800,92,960,99.7C1120,107,1280,146,1440,168.7C1600,192,1760,199,1920,168.7C2080,138,2240,69,2400,49.8C2560,31,2720,61,2880,76.7C3040,92,3200,92,3360,92C3520,92,3680,92,3840,92C4000,92,4160,92,4320,99.7C4480,107,4640,123,4800,141.8C4960,161,5120,184,5280,161C5440,138,5600,69,5760,69C5920,69,6080,138,6240,138C6400,138,6560,69,6720,38.3C6880,8,7040,15,7200,46C7360,77,7520,130,7680,130.3C7840,130,8000,77,8160,80.5C8320,84,8480,146,8640,164.8C8800,184,8960,161,9120,134.2C9280,107,9440,77,9600,80.5C9760,84,9920,123,10080,138C10240,153,10400,146,10560,122.7C10720,100,10880,61,11040,69C11200,77,11360,130,11440,157.2L11520,184L11520,230L11440,230C11360,230,11200,230,11040,230C10880,230,10720,230,10560,230C10400,230,10240,230,10080,230C9920,230,9760,230,9600,230C9440,230,9280,230,9120,230C8960,230,8800,230,8640,230C8480,230,8320,230,8160,230C8000,230,7840,230,7680,230C7520,230,7360,230,7200,230C7040,230,6880,230,6720,230C6560,230,6400,230,6240,230C6080,230,5920,230,5760,230C5600,230,5440,230,5280,230C5120,230,4960,230,4800,230C4640,230,4480,230,4320,230C4160,230,4000,230,3840,230C3680,230,3520,230,3360,230C3200,230,3040,230,2880,230C2720,230,2560,230,2400,230C2240,230,2080,230,1920,230C1760,230,1600,230,1440,230C1280,230,1120,230,960,230C800,230,640,230,480,230C320,230,160,230,80,230L0,230Z"></path>
        </svg>

        <div class="mb-5 mt-3" id="projects">
            <div class="text-center text-primary mb-2">
                <h1 class="fw-bold mb-2">{{ $t("projects.title") }}</h1>
                <p class="mb-3" v-html="$t('projects.subtitle')"></p>
            </div>

            <div class="container px-3">
                <!-- Image previewer -->
                <app-tiny-box v-model="previewIndex"
                              loop
                              @close="closePreviewer"
                              :images="previewImages" />
                <!-- END Image previewer -->

                <!-- START project fitlers -->
                <div class="project-fiters row mb-2 p-2">
                    <div class="col-md-12 text-center">
                        <div class="project-filter-item  mb-1 badge bg-secondary p-2 mr-5 text-white rounded scale-on-hover-02 pointer" v-on:click="setFilterService(service.id)" :class="{'bg-blue': service.id == filterServiceId}" v-for="service in services" v-bind:key="service.id">
                            <span v-if="service.icon" class="material-icons small mr-5">{{ service.icon}}</span>
                            <span class="small">
                                {{ $t(service.title) }}
                            </span>
                        </div>
                    </div>
                </div>
                <!-- END project fitlers -->
                <div class="row p-2">

                    <div v-if="filteredProjects.length == 0" class="col-md-12 text-center text-primary">
                        <div class="material-icons">not_interested</div>
                        <div>{{ $t("general.noResultsForSelectedService") }}</div>
                    </div>
                    <div v-else class="col-lg-4 col-md-12 mb-4"
                         v-for="project in filteredProjects"
                         v-bind:key="project.id">
                        <div class="project-card-body text-light text-center rounded scale-on-hover-02 shadow">

                            <!-- 500 x 280 -->
                            <img v-if="project.images[0].src" :src="project.images[0].src" class="full-width project-img rounded" />

                            <div class="project-card-description rounded pointer p-2" @click="previewPhoto(project.id, 0)">
                                <div v-html="$t(project.desc)" class="project-card-description-title rounded text-center mb-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import TinyBox from "vue-tinybox"


    export default {
        data() {
            return {
                previewIndex: null,
                previewImages: [],
                filterServiceId: null,
                services: [
                    {
                        id: null,
                        title: "general.all",
                        icon: null,
                    },
                    {
                        id: 1,
                        title: "services.structures.title",
                        subtitle: "services.structures.subtitle",
                        icon: "dashboard",
                    },
                    {
                        id: 2,
                        title: "services.hydro.title",
                        subtitle: "services.hydro.subtitle",
                        icon: "invert_colors",
                    },
                    {
                        id: 3,
                        title: "services.engineering.title",
                        subtitle: "services.engineering.subtitle",
                        icon: "settings_input_component",
                    },
                    {
                        id: 4,
                        title: "services.bim.title",
                        subtitle: "services.bim.subtitle",
                        icon: "view_in_ar",
                    }
                ],
                projects: [
                    {
                        id: 1,
                        title: "projects.project_1.title",
                        desc: "projects.project_1.description",
                        serviceId: 1,
                        images: [
                            {
                                src: require("@/assets/img/projects/project_1/image_1.jpg"),
                                thumbnail: require("@/assets/img/projects/project_1/image_1.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_1/image_2.jpg"),
                                thumbnail: require("@/assets/img/projects/project_1/image_2.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_1/image_3.jpg"),
                                thumbnail: require("@/assets/img/projects/project_1/image_3.jpg"),
                            },
                        ],
                    },
                    {
                        id: 2,
                        title: "projects.project_2.title",
                        desc: "projects.project_2.description",
                        serviceId: 2,
                        images: [
                            {
                                src: require("@/assets/img/projects/project_2/image_1.jpg"),
                                thumbnail: require("@/assets/img/projects/project_2/image_1.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_2/image_2.jpg"),
                                thumbnail: require("@/assets/img/projects/project_2/image_2.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_2/image_3.jpg"),
                                thumbnail: require("@/assets/img/projects/project_2/image_3.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_2/image_4.jpg"),
                                thumbnail: require("@/assets/img/projects/project_2/image_4.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_2/image_5.jpg"),
                                thumbnail: require("@/assets/img/projects/project_2/image_5.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_2/image_6.png"),
                                thumbnail: require("@/assets/img/projects/project_2/image_6.png"),
                            },
                            {
                                src: require("@/assets/img/projects/project_2/image_7.png"),
                                thumbnail: require("@/assets/img/projects/project_2/image_7.png"),
                            },
                        ],
                    },
                    {
                        id: 3,
                        title: "projects.project_3.title",
                        desc: "projects.project_3.description",
                        serviceId: 1,
                        images: [
                            {
                                src: require("@/assets/img/projects/project_3/image_1.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_1.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_2.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_2.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_3.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_3.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_4.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_4.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_5.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_5.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_6.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_6.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_7.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_7.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_3/image_8.jpg"),
                                thumbnail: require("@/assets/img/projects/project_3/image_8.jpg"),
                            },
                        ],
                    },
                    {
                        id: 4,
                        title: "projects.project_4.title",
                        desc: "projects.project_4.description",
                        serviceId: 1,
                        images: [
                            {
                                src: require("@/assets/img/projects/project_4/image_1.jpg"),
                                thumbnail: require("@/assets/img/projects/project_4/image_1.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_4/image_2.jpg"),
                                thumbnail: require("@/assets/img/projects/project_4/image_2.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_4/image_3.jpg"),
                                thumbnail: require("@/assets/img/projects/project_4/image_3.jpg"),
                            },
                        ],
                    },
                    {
                        id: 5,
                        title: "projects.project_5.title",
                        desc: "projects.project_5.description",
                        serviceId: 2,
                        images: [
                            {
                                src: require("@/assets/img/projects/project_5/image_2.jpg"),
                                thumbnail: require("@/assets/img/projects/project_5/image_2.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_1.jpg"),
                                thumbnail: require("@/assets/img/projects/project_5/image_1.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_3.jpg"),
                                thumbnail: require("@/assets/img/projects/project_5/image_3.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_4.jpg"),
                                thumbnail: require("@/assets/img/projects/project_5/image_4.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_5.jpg"),
                                thumbnail: require("@/assets/img/projects/project_5/image_5.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_6.jpg"),
                                thumbnail: require("@/assets/img/projects/project_5/image_6.jpg"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_7.png"),
                                thumbnail: require("@/assets/img/projects/project_5/image_7.png"),
                            },
                            {
                                src: require("@/assets/img/projects/project_5/image_8.png"),
                                thumbnail: require("@/assets/img/projects/project_5/image_8.png"),
                            },
                        ],
                    },
                    {
                        id: 6,
                        title: "projects.project_6.title",
                        desc: "projects.project_6.description",
                        serviceId: 2,
                        images: [
                            {
                                src: require("@/assets/img/projects/project_6/image_1.png"),
                                thumbnail: require("@/assets/img/projects/project_6/image_1.png"),
                            },
                            {
                                src: require("@/assets/img/projects/project_6/image_2.png"),
                                thumbnail: require("@/assets/img/projects/project_6/image_2.png"),
                            },
                            {
                                src: require("@/assets/img/projects/project_6/image_3.png"),
                                thumbnail: require("@/assets/img/projects/project_6/image_3.png"),
                            }
                        ],
                    },
                    {
                        id: 7,
                        title: "projects.project_7.title",
                        desc: "projects.project_7.description",
                        serviceId: 2,
                        images: [
                            
                            {
                                src: require("@/assets/img/projects/project_7/image_2.png"),
                                thumbnail: require("@/assets/img/projects/project_7/image_2.png"),
                            },
                            {
                                src: require("@/assets/img/projects/project_7/image_1.png"),
                                thumbnail: require("@/assets/img/projects/project_7/image_1.png"),
                            },
                        ],
                    },
                ],
            };
        },
        methods: {
            previewPhoto(projectId, imgIndex) {
                this.previewImages = this.projects.find(x => x.id == projectId).images;
                this.previewIndex = imgIndex;
            },
            closePreviewer() {
                this.previewImages = [];
                this.previewIndex = null;
            },
            rightClick(e) {
                e.preventDefault();
            },
            setFilterService(serviceId) {
                this.filterServiceId = serviceId;
            }
        },
        computed: {
            filteredProjects() {
                return this.projects.filter(p => this.filterServiceId == null || p.serviceId == this.filterServiceId);
            }
        },
        components: {
            appTinyBox: TinyBox
        },
        filters: {
            lowercase(text) {
                return text.toLowerCase();
            },
            uppercase(text) {
                return text.toUpperCase();
            }
        }
    };
</script>

<style scoped>

    #wave {
        position: relative !important;
        top: -1px !important;
    }

    .project-card-body {
        position: relative;
    }

    .project-card-description {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.4);
        transition: all ease 2s;
        z-index: 999 !important;
        height: 100%;
        width: 100%;
    }
        .project-card-description:hover {
            background-color: rgba(0,0,0,0.1);
            transition: all ease .5s;
        }

    .project-card-description-title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 95%;
        margin: 0 2.5%;
        font-weight: 500;
        transition: all ease 1s;
        padding: 0 2px 0 2px;
    }

    .project-card-description:hover .project-card-description-title {
        background-color: rgba(0,0,0,0.4);
        transition: all ease .5s;
    }

    .project-filter-item:hover {
        background-color: #2164ae !important;
    }

    .bg-secondary {
        --bs-bg-opacity: 0.7 !important;
    }
    
</style>
