<template>
  <div class="page pt-0 pb-0" id="about-us">
    <div class="text-center px-3">
      <h1 class="fw-bold mb-2 text-primary">{{ $t("aboutUs.title") }}</h1>
      <div class="subtitle text-primary">{{ $t("aboutUs.subtitle") }}</div>
    </div>
    <div class="container px-3 pt-3 pb-3">
      <div class="row ms-0 me-0 mb-4">
        <div class="col-md-3 align-self-md-center text-center sm-d-none">
            <img src="../assets/logo/hking-logo-icon.png" class="about-us-img-left" />
        </div>
        <div class="col-md-6 text-justify mb-5" v-html="$t('aboutUs.content')">
        </div>
        <div class="col-md-3 align-self-md-center text-center">
            <img src="../assets/img/about-us/civil-engineer.png" class="full-width about-us-img-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style scoped>
   




  /*  @keyframes typing {
        from {
            width: 0
        }

        to {
            width: 100%
        }
    }

    .typewriter {
        overflow: hidden;
        width: 0;
        border-right: .15em solid orange;
        animation: typing 1s forwards;
    }*/
</style>
