<template>
    <div id="navbar" class="bg-white" style="position: fixed; z-index: 200; width: 100%">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-inverse pt-3 pt-sm-3">
                <!-- Container wrapper -->
                <div class="container">
                    <!-- Toggle button -->
                    <a class="p-2" href="javascript:;">
                        <img :src="logo"
                             class="navbar-logo"
                             alt="hking-consult-logo"
                             loading="lazy" />
                    </a>
                    <div class="collapse navbar-collapse justify-content-center font-weight-bold" id="navbarSupportedContent">
                        <ul class="navbar-nav mb-2 mb-lg-0 text-light">
                            <li class="nav-item">
                                <a class="nav-link scale-on-hover" href="javascript:;" v-on:click="scrollTo('home')">{{ $t("header.home") | uppercase }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scale-on-hover" href="javascript:;" v-on:click="scrollTo('about-us')">{{ $t("header.aboutUs") | uppercase }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scale-on-hover" href="javascript:;" v-on:click="scrollTo('services')">{{ $t("header.services") | uppercase }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link scale-on-hover" href="javascript:;" v-on:click="scrollTo('projects')">{{ $t("header.projects") | uppercase }}</a>
                            </li>
                        </ul>
                        <a class="btn btn-outline-primary scale-on-hover font-weight-bold" href="javascript:;" v-on:click="scrollTo('contact-us')">{{ $t("header.contactUs") | uppercase }}</a>
                        <!-- Left links -->
                    </div>
                    <!-- Collapsible wrapper -->
                    <LanguageSwitcher />
                </div>
                <!-- Container wrapper -->
            </nav>
        </div>
    </div>
</template>

<script>

    import LanguageSwitcher from "../components/LanguageSwitcher.vue"

    export default {
        name: "Navbar",
        components: {
            LanguageSwitcher
        },
        methods: {
            scrollTo(id) {
                document.getElementById(id).scrollIntoView(true);
            },
        },
        computed: {
            logo() {
                let logoUrl = require(`@/assets/logo/hking-logo-${this.$i18n.locale}.png`);
                return logoUrl;
            }
        },
        filters: {
            uppercase(text) {
                return text.toUpperCase();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .navbar-logo {
        height: 6rem !important;
    }

    @media only screen and (max-width: 1200px) {
        .navbar-logo {
            height: 3.8rem !important;
        }
    }

</style>
